<template>
  <Portlet
    :title="portletTitle"
    icon="chart-area"
    class="reportByType"
  >
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <template v-if="categories && axis && series">
        <kendo-chart
          ref="chart"
          :category-axis-categories="categories"
          :legend-position="'top'"
          :chart-area-background="''"
          :series-defaults-type="'line'"
          :series-defaults-style="'smooth'"
          :category-axis-reverse="true"
          :value-axis="axis"
          :value-axis-axis-crossing-value="'-10'"
          :category-axis-major-grid-lines-visible="false"
          :category-axis-labels-rotation="'auto'"
          :theme="'sass'"
          :series="series"
        />
      </template>
      <p v-else>
        {{ $t('noDataAvailable') }}
      </p>
    </template>
  </Portlet>
</template>

<script>
import { responsiveKendoGridMixin } from '@/mixins/responsiveKendoGridMixin.js';
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';

export default {
  name: 'ReportByType',
  mixins: [
    dateTimeMixin,
    responsiveKendoGridMixin
  ],
  props: {
    chartData: {
      type: Object,
      required: true
    },
    portletTitle: {
      type: String,
      required: false,
      default: "Report by type"
    }
  },
  data () {
    return {
      loading: true,
      categories: null,
      tooltiptemplate: '#= series.name #: #= value #',
      axis: [{
        name: "Percent",
        min: 0,
        max: 100,
        labels: {
          format: "{0}%",
          position: 'start'
        }
      },
      {
        name: "Count",
        labels: {
          format: "{0}",
          position: 'end'
        }
      }],
      series: null,
      cancelSource: null
    }
  },
  created () {
    this.handleResponse(this.chartData);
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy () {
    this.cancelRequest();
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize () {
      this.responsiveKendoGridMixin_refresh(this.$refs.chart);
    },
    cancelRequest () {
      if (this.cancelSource) {
        this.cancelSource.cancel();
      }
    },
    handleResponse (response) {
      this.loading = true;     
      this.categories = response.data.map(o => this.$options.filters.dateTime_date(o.createDate));  

      this.series = [{
        data: response.data.map(o => o.totalRuns),
        type: "area",
        color: "#73c100",
        line: {
          style: "smooth"
        },
        opacity: .666,
        axis: "Count",
        tooltip: {
          visible: true,
          template: "Date: #= category # <br/> Total: #= value #"
        },
        name: "Total Runs"
      },
      {
        data: response.data.map(o => o.totalBadRuns),
        color: "rgb(99, 0, 30)",
        type: "area",
        opacity: .666,
        line: {
          style: "smooth"
        },
        tooltip: {
          visible: true,
          template: "Date: #= category # <br/> Total Bad: #= value #"
        },
        axis: "Count",
        name: "Total Bad Runs"
      },
      {
        data: response.data.map(o => o.totalBadRunsPercent.toFixed(3)),
        color: "rgb(99, 0, 30)",
        line: {
          style: "smooth"
        },
        tooltip: {
          visible: true,
          template: "Date: #= category # <br/> Total Bad: #= value #%"
        },
        axis: "Percent",
        name: "Bad Runs Percent"
      }];
      this.loading = false;
    }
  }
}
</script>